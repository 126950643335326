import i18n from "i18next";

import { initReactI18next} from 'react-i18next';

import langRu from './lang/ru.json';
import langEn from './lang/en.json';

const toEn = langEn;
const toRu = langRu;

i18n
  .use(initReactI18next)
  .init( {
    resources : {
      en: { translation: toEn},
      ru: { translation: toRu},
    },
    fallbackLng: 'ru',
    interpolation: { escapeValue: false},
    react: {
      useSuspense: false,
    },
  });

  export default i18n;