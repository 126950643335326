
import { useTranslation } from "react-i18next";
import { useState } from "react";

function Count(){

    const { t } = useTranslation();

    const [p, setP] = useState();
    const [d, setD] = useState();
    const [z, setZ] = useState();

    fetch('https://data.seotiv.com/data', {
        method: 'POST',
    })
    .then((response) => {
      return response.json();
      //return response;
    })
    .then((data) => {
      //console.log();
      setP(data[0].month);
      setD(data[0].process);
      setZ(data[0].week);
    });


    return (
        <div className="counter">
            <h2>{t('stat')}</h2>
            <div className="row">
                <div className="block">
                    <div className="number">{p}</div>
                    {/* <div className="number">17</div> */}
                    <div className="text">{t('project')}</div>
                </div>
                <div className="block">
                    <div className="number">{z}</div>
                    {/* <div className="number">1631</div> */}
                    <div className="text">{t('improv')}</div>
                </div>
                <div className="block">
                    <div className="number">{d}</div>
                    {/* <div className="number">168</div> */}
                    <div className="text">{t('tasks')}</div>
                </div>
            </div>
        </div>
    )
}

export default Count;