import { useRef } from "react";

import { useTranslation } from "react-i18next";
import i18n from "i18next";

function Intro(){

    const langs = useRef();

    const { t } = useTranslation();

    const OnChange = (event) => {
        i18n.changeLanguage(event.target.dataset.name);
        Array.from(langs.current.children).map(item => item.classList.remove('active'));
        event.target.classList.toggle('active');
    }



    return (
        <div className="intro">
            <div className="langs-choice" ref={langs}>
                <div onClick={OnChange} data-name="ru" className="active">Рус</div>
                <div onClick={OnChange} data-name="en">Eng</div>
            </div>

            <div className="social-media">
                <a href="mailto:inbox@seotiv.com"><div className="sm em"></div></a>
                <a href="https://wa.me/77773372172" target="a_blank"><div className="sm wa"></div></a>
                <a href="tel:+77773372172"><div className="sm ph"></div></a>
            </div>

            <div className="logo">
                <div></div>
            </div>

            <h1 className="slogan">
                {t('slogan')}
            </h1>

            <div className="advant">
                <div className="vys-eff">{t('effic')}</div>
                <div className="dol-sot">{t('coop')}</div>
                <div className="kac-pro">{t('qual')}</div>
            </div>

        </div>


    )
}

export default Intro;