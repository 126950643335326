import './style.css';

import Intro from './components/Intro';
import Count from './components/Count';


function App() {

  return (
      <>
        <Intro />
        <Count />
      </>
  );
  
}

export default App;